import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken } from "firebase/messaging";
// import { axiosInstance } from "@/axios";

const firebaseConfig = {
  apiKey: "AIzaSyCME2CYaIso7PngM2wihV4LZGB_JzZoKjc",
  authDomain: "silema.firebaseapp.com",
  projectId: "silema",
  storageBucket: "silema.appspot.com",
  messagingSenderId: "565898129046",
  appId: "1:565898129046:web:7245c59623deb1f72c1cb7",
  measurementId: "G-1J6JS28GMD",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// export const messaging = getMessaging(app);

// if (
//   Notification.permission === "default" ||
//   Notification.permission === "denied"
// ) {
//   Notification?.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("El usuario ha permitido las notificaciones");
//       // Aquí puedes iniciar las notificaciones
//     } else {
//       console.log("El usuario ha rechazado las notificaciones");
//       // Aquí puedes mostrar un mensaje en la interfaz de usuario
//     }
//   });
// } else if (Notification.permission === "granted") {
//   console.log("El usuario ya ha permitido las notificaciones");
//   // Aquí puedes iniciar las notificaciones
// }

// // Solicita permiso y obtén el token FCM
// async function requestPermissionAndGetToken() {
//   try {
//     await Notification.requestPermission();
//     await getToken(messaging);
//   } catch (error) {
//     console.error("Error requesting permission or getting token:", error);
//   }
// }

// async function subscribeToTopic() {
//   try {
//     const token = await getToken(messaging);
//     const resSubscribe = await axiosInstance.post(
//       "trabajadores/suscribirseMessaging",
//       { token }
//     );
//     if (!resSubscribe.data.ok) {
//       throw Error(
//         "No se ha podido realizar la subscripción al sistema de notificaciones"
//       );
//     }
//   } catch (error) {
//     console.error("Error subscribing to topic:", error);
//   }
// }

// if ("serviceWorker" in navigator && "PushManager" in window) {
//   requestPermissionAndGetToken();
//   subscribeToTopic();
// } else {
//   console.warn(
//     "Las notificaciones push o los Service Workers no son soportados en este navegador"
//   );
// }
